<template>
  <div class="login">
    <el-row type="flex" justify="center" align="middle" style="width: 100%; height: 100%;">
      <el-col :xs="14" :sm="12" :md="10" :lg="8">
        <div class="content">
          <p class="title">北京云达在线-物流配送管理系统</p>
          <el-row style="padding: 30px 0;">
            <el-col
                :span="12"
                v-for="(item, index) in type"
                :key="item.id"
                :class="['type', current == index ? 'active' : '']"
            >
              <span @click="typeChange(index)">{{ item.name }} </span>
            </el-col>
          </el-row>
          <el-form v-show="current === 0" ref="accountForm" :model="accountForm" :rules="accountRules" label-width="55px">
            <el-form-item label="账号" prop="username">
              <el-input placeholder="请输入账号" v-model.trim="accountForm.username"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input type="password" show-password placeholder="请输入密码" v-model.trim="accountForm.password"></el-input>
            </el-form-item>
          </el-form>
          <el-form v-show="current === 1" ref="messageForm" :model="messageForm" :rules="messageRules" label-width="65px">
            <el-form-item label="手机号" prop="phone">
              <el-input placeholder="请输入手机号" v-model.trim="messageForm.phone"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input placeholder="请输入验证码" v-model.trim="messageForm.code"></el-input>
            </el-form-item>
          </el-form>
          <div>
            <el-button
                type="primary"
                style="width: 100%;"
                native-type="submit"
                :loading="btnLoading"
                @click="submitForm()">登录
            </el-button>
          </div>
        </div>
      </el-col>
    </el-row>
	<div style="position: fixed;left: 0;right: 0;bottom: 0;background-color: #e7e7e7;text-align: center;line-height: 30px;height: 30px;">
		©京ICP备18055317号-2
	</div>
  </div>
</template>

<script>
import {userLogins} from '../../api/login';
import {mapMutations} from 'vuex';

export default {
  data() {
    return {
      btnLoading: false,
      current: 0,
      type: [
        {
          id: "accountForm",
          name: "账号登录"
        },
        {
          id: "messageForm",
          name: "短信验证登录"
        }
      ],
      accountForm: {
        username: '',
        password: ''
      },
      messageForm: {
        phone: '',
        code: '',
      },
      accountRules: {
        username: [
          {required: true, message: '请输入账号', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
        ]
      },
      messageRules: {
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ]
      },
    }
  },
  created() {
    document.onkeypress = (e) => {
      var keycode = document.all ? event.keyCode : e.which;
      if (keycode == 13) {
        this.submitForm();
        return false;
      }
    };
  },
  mounted() {
  },
  methods: {
    ...mapMutations('login', ['setUser']),
    ...mapMutations('menu', ['setMenuList']),
    typeChange(index) {
      this.current = index;
      const formType = this.type[this.current].id;
      this.$refs[formType].resetFields();
    },
    async userLogins(params) {
      this.btnLoading = true;
      try {
        let data = await userLogins(params);
        if (data.token) {
          this.setUser({
            id: data.id,
            roleId: data.roleId,
            token: data.token,
            user: data.user,
            phone: data.phone,
            code: data.code,
            roleName: data.roleName,
            roleType: data.roleType
          });
          this.$router.push('/home');
        }
      } catch (error) {
        console.log("error:", error)
      }
      this.btnLoading = false;
    },
    submitForm() {
      const formType = this.type[this.current].id;
      console.log(formType)
      this.$refs[formType].validate((valid) => {
        if (valid) {
          const params = {};
          if (formType === "accountForm") {
            params.phone = this.accountForm.username;
            params.password = this.accountForm.password;
          }
          if (formType === "messageForm") {
            params.phone = this.messageForm.phone;
            params.phoneCode = this.messageForm.code;
          }
          this.userLogins(params);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  background-color: #F0F2F5;

  .content {
    box-sizing: border-box;
    padding: 30px 40px;
    width: 100%;
    text-align: center;
    background-color: #FFFFFF;
    border-radius: 10px;

    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .type {
      color: #A4A4A4;
      cursor: pointer;
    }

    .active {
      color: #0000B8;
    }
  }

}
</style>